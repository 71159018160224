import React from "react";
import {Col, Row, Container}from"react-bootstrap";
import "./ProductServicesMainBanner.css";
import ProductServicesBanner from"./../../../Assests/images/ProductServicesBanner.png"
export default function ProductServicesMainBanner(){
    return(
        <>
        <Container fluid className="ProductServicesMainBanner">
            <Container>
        <Row>
       <Col sm={12} md={6} lg={6}>
      <p className="ProSerBannerTeaxt"> 
      <span> Services We Offer </span> <br/>
      Bhaijee provides a wide range of products and services that fulfill a broad mix of investment needs.


      </p>
       </Col>
       <Col sm={12} md={6} lg={6}>
        <img src={ProductServicesBanner} className="img-fluid" />    
       </Col>
       </Row>

       </Container>
       </Container>
       <Container>
        <Row>
            <Col>
            <h1 className="PorductHeading"> PRODUCTS AND SERVICES </h1>
            </Col>
        </Row>
       </Container>
       
        </>
    )
}