import { Container, Col, Row, Table } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./Downloads.css";
import PDFIMG from "./../../Assests/images/pdf.png";
import ManImg from "./../../Assests/images/man.png";
import Format1 from "../../Assests/pdf/BRACCOUNTOPENINGFORMAT.pdf";
import Format2 from "../../Assests/pdf/BRCLOSUREFORMAT.pdf";
import Pmla1 from "../../Assests/pdf/PMLAPOLICY.pdf";
import Pmla2 from "../../Assests/pdf/RMSPOLICY.pdf";
import Pmla3 from "../../Assests/pdf/SurviellancePolicy.pdf";
import Letter1 from "../../Assests/pdf/LETTERFORCHANGEOFADDRESS.pdf";
import Letter2 from "../../Assests/pdf/LETTERFORCHANGEOFEMAILANDCONTACTNUMBER.pdf";
import Letter3 from "../../Assests/pdf/ModificationDeletionRequestForm.pdf";
import Letter4 from "../../Assests/pdf/MARGININVOCATIONREQUESTFORM.pdf";
import Letter5 from "../../Assests/pdf/MARGINUNPLEDGEREQUESTFORM.pdf";
import Letter6 from "../../Assests/pdf/MARGINPLEDGEREQUESTFORM.pdf";

function Downloads() {
  return (
    <>
      <section className="download-hero-banner">
        <Container>
          <Row>
            <Col xs={12} md={5} lg={6}>
              <h1>Download</h1>
              <h5>
                A collective list of forms and application
              </h5>
            </Col>
            <Col xs={12} md={7} lg={6}>
              <img className="img-fluid" src={ManImg} alt={ManImg} />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="download-table-view">
        <Container>
          <Row>
            <Col xs={12} md={12} lg={12}>
              <div className="">
                <h3>
                  Downloads
                </h3>
                <div className="download-inner">
                  <Tabs
                    defaultActiveKey="formats"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                  >
                    {/* <Tab eventKey="kyc-forms" title="Kyc Forms">
                      <div>
                        <Table responsive>
                          <thead>
                            <tr>
                              <th>Sr no.</th>
                              <th>File Name</th>
                              <th>Date</th>
                              <th>Download</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1</td>
                              <td>
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry.
                              </td>
                              <td>27-Aug-2022</td>
                              <td>
                                <img
                                  className="img-fluid"
                                  src={PDFIMG}
                                  alt={PDFIMG}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td>
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry.
                              </td>
                              <td>27-Aug-2022</td>
                              <td>
                                <img
                                  className="img-fluid"
                                  src={PDFIMG}
                                  alt={PDFIMG}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>3</td>
                              <td>
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry.
                              </td>
                              <td>27-Aug-2022</td>
                              <td>
                                <img
                                  className="img-fluid"
                                  src={PDFIMG}
                                  alt={PDFIMG}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>4</td>
                              <td>
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry.
                              </td>
                              <td>27-Aug-2022</td>
                              <td>
                                <img
                                  className="img-fluid"
                                  src={PDFIMG}
                                  alt={PDFIMG}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>5</td>
                              <td>
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry.
                              </td>
                              <td>27-Aug-2022</td>
                              <td>
                                <img
                                  className="img-fluid"
                                  src={PDFIMG}
                                  alt={PDFIMG}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>6</td>
                              <td>
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry.
                              </td>
                              <td>27-Aug-2022</td>
                              <td>
                                <img
                                  className="img-fluid"
                                  src={PDFIMG}
                                  alt={PDFIMG}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>7</td>
                              <td>
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry.
                              </td>
                              <td>27-Aug-2022</td>
                              <td>
                                <img
                                  className="img-fluid"
                                  src={PDFIMG}
                                  alt={PDFIMG}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>8</td>
                              <td>
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry.
                              </td>
                              <td>27-Aug-2022</td>
                              <td>
                                <img
                                  className="img-fluid"
                                  src={PDFIMG}
                                  alt={PDFIMG}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>9</td>
                              <td>
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry.
                              </td>
                              <td>27-Aug-2022</td>
                              <td>
                                <img
                                  className="img-fluid"
                                  src={PDFIMG}
                                  alt={PDFIMG}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>10</td>
                              <td>
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry.
                              </td>
                              <td>27-Aug-2022</td>
                              <td>
                                <img
                                  className="img-fluid"
                                  src={PDFIMG}
                                  alt={PDFIMG}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </Tab> */}
                    {/* <Tab eventKey="trading-software" title="Trading Software">
                      <div>some 2</div>
                    </Tab> */}
                    <Tab eventKey="formats" title="Formats">
                    <div>
                        <Table responsive>
                          <thead>
                            <tr>
                              <th>Sr no.</th>
                              <th className="text-left">File Name</th>
                              <th className="text-center">Download</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1.</td>
                              <td className="text-left">BR Account Opening Format</td>
                              <td className="text-center">
                                <a href={Format1} target="_blank"><img className="img-fluid" src={PDFIMG} alt={PDFIMG}/></a>
                              </td>
                            </tr>
                            <tr>
                              <td>2.</td>
                              <td className="text-left">BR Closure Format</td>
                              <td className="text-center">
                                <a href={Format2} target="_blank"><img className="img-fluid" src={PDFIMG} alt={PDFIMG}/></a>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </Tab>
                    <Tab eventKey="policy" title="Policy / Procedure">
                      <div>
                      <Table responsive>
                          <thead>
                            <tr>
                              <th>Sr no.</th>
                              <th className="text-left">File Name</th>
                              <th className="text-center">Download</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1.</td>
                              <td className="text-left">PMLA Policy</td>
                              <td className="text-center">
                                <a href={Pmla1} target="_blank"><img className="img-fluid" src={PDFIMG} alt={PDFIMG}/></a>
                              </td>
                            </tr>
                            <tr>
                              <td>2.</td>
                              <td className="text-left">RMS Policy</td>
                              <td className="text-center">
                                <a href={Pmla2} target="_blank"><img className="img-fluid" src={PDFIMG} alt={PDFIMG}/></a>
                              </td>
                            </tr>
                            <tr>
                              <td>3.</td>
                              <td className="text-left">Surviellance Policy</td>
                              <td className="text-center">
                                <a href={Pmla3} target="_blank"><img className="img-fluid" src={PDFIMG} alt={PDFIMG}/></a>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </Tab>
                    <Tab eventKey="forms" title="Forms">
                      <div>
                      <Table responsive>
                          <thead>
                            <tr>
                              <th>Sr no.</th>
                              <th className="text-left">File Name</th>
                              <th className="text-center">Download</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1.</td>
                              <td className="text-left">Letter For Change Of Address</td>
                              <td className="text-center">
                                <a href={Letter1} target="_blank"><img className="img-fluid" src={PDFIMG} alt={PDFIMG}/></a>
                              </td>
                            </tr>
                            <tr>
                              <td>2.</td>
                              <td className="text-left">Letter For Change Of Email And Contact Number</td>
                              <td className="text-center">
                                <a href={Letter2} target="_blank"><img className="img-fluid" src={PDFIMG} alt={PDFIMG}/></a>
                              </td>
                            </tr>
                            <tr>
                              <td>3.</td>
                              <td className="text-left">Modification Deletion Request Form</td>
                              <td className="text-center">
                                <a href={Letter3} target="_blank"><img className="img-fluid" src={PDFIMG} alt={PDFIMG}/></a>
                              </td>
                            </tr>
                            <tr>
                              <td>4.</td>
                              <td className="text-left">Margin Invocation Request Form</td>
                              <td className="text-center">
                                <a href={Letter4} target="_blank"><img className="img-fluid" src={PDFIMG} alt={PDFIMG}/></a>
                              </td>
                            </tr>
                            <tr>
                              <td>5.</td>
                              <td className="text-left">Margin Unpledge Request Form</td>
                              <td className="text-center">
                                <a href={Letter5} target="_blank"><img className="img-fluid" src={PDFIMG} alt={PDFIMG}/></a>
                              </td>
                            </tr>
                            <tr>
                              <td>6.</td>
                              <td className="text-left">Margin Pledge Request Form</td>
                              <td className="text-center">
                                <a href={Letter6} target="_blank"><img className="img-fluid" src={PDFIMG} alt={PDFIMG}/></a>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Downloads;
