import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import './BankAccount.css';

function BankAccount() {
    return (
        <>
            <Container>
                <Row>
                    <Col xs={12} className="ContactLeadfrom contact-table bank-account-sc">
                        <div className="ContactLeadformHeading"> 
                        Details of Client Bank Account
                        </div>
                        <p>The Details of Client Bank Accounts are as follows:</p>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Sr No</th>
                                        <th>Name of Bank </th>
                                        {/* <th>Name of bank Account</th> */}
                                        <th>Bank Account Number</th>
                                        <th>IFSC CODE</th>
                                        <th>Branch</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>HDFC BANK LIMITED</td>
                                        {/* <td>BHAIJEE PORTFOLIO PRIVATE LIMITED</td> */}
                                        <td>00030340030902</td>
                                        <td>HDFC0000003</td>
                                        <td>Kastubra Gandhi marg, New Delhi-110001</td>
                                    </tr>
                                    {/* <tr>
                                        <td>2</td>
                                        <td>HDFC BANK LIMITED</td>
                                        <td>BHAIJEE PORTFOLIO PRIVATE LIMITED</td>
                                        <td>00030340021000</td>
                                        <td>HDFC0000003</td>
                                        <td>COMMODITY</td>
                                    </tr> */}
                                    <tr>
                                        <td>2</td>
                                        <td>HDFC BANK LIMITED</td>
                                        {/* <td>BHAIJEE PORTFOLIO PRIVATE LIMITED</td> */}
                                        <td>00030340034956</td>
                                        <td>HDFC0000003</td>
                                        <td>Kastubra Gandhi marg, New Delhi-110001</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="contact-line">
                            <p>“Investors are requested to note that Bhaijee Portfolio Pvt. Ltd. is permitted to receive money from investor through designated bank accounts only named as Up streaming Client Nodal Bank Account (USCNBA). Bhaijee Portfolio Pvt. Ltd. is also required to disclose these USCNB accounts to Stock Exchange. Hence, you are requested to use following USCNB accounts only for the purpose of dealings in your trading account with us. The details of these USCNB accounts are also displayed by Stock Exchanges on their website under “Know/ Locate your Stock Broker.”</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default BankAccount;