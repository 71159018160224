import React from "react";
import { Container, Col, Row } from "react-bootstrap";
// import AllData from "./productServices.json";
// import Image1 from "../../../Assests/images/1.png";
import "./ProductServices.css";
const data = [
  {
    id: "01",
    img: "./images/1.png",
    service: "Equities",
    content:
      "Investing in shares or stock market is the best route to long-term wealth accumulation...",
  },
  {
    id: "02",
    img: "./images/2.png",
    service: "Derivatives",
    content:
      "A derivative is an instrument whose value is derived from the value of one or more underlying...",
  },
  {
    id: "03",
    img: "./images/3.png",
    service: "Commodities",
    content:
      "Commodities Derivative market has emerged as a new avenue for investors. Today...",
  },
  {
    id: "04",
    img: "./images/4.png",
    service: "Currency",
    content:
      "Currency derivatives is used to hedge the Forex risk by bank, importers and exporters. Currency Derivatives...",
  },
  {
    id: "05",
    img: "./images/5.png",
    service: "IPO",
    content:
      "Initial public offering is the process by which a private company can go public by sale of its stocks...",
  },
  {
    id: "06",
    img: "./images/1.png",
    service: "Depository Participant Services",
    content:
      "Bhaijee is registered as Depository Participant with CDSL. Bhaijee offers services related to demat...",
  },
];
function ProductServices() {
  return (
    <>
      <section className="product-services">
        <Container>
          <Row>
            <Col xs={12} md={5} lg={5}>
              <h1>PRODUCTS AND SERVICES</h1>
            </Col>
            <Col xs={12} md={7} lg={7}>
              <h5>
                Bhaijee provides a wide range of products and services that
                <br />
                fulfill a broad mix of investment needs.
              </h5>
            </Col>
          </Row>
          <Row>
            {data.map((data, i) => {
              return (
                <Col xs={12} md={4} sm={6} lg={4} key={i}>
                  <div className="each-product-service">
                    <h4>
                      <span>{data.id}</span>| {data.service}
                    </h4>
                    {/* <img src={Image1} alt="some-img" /> */}
                    <img src={data.img} alt={i} />
                    <p>{data.content}</p>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Container>
      </section>
    </>
  );
}
export default ProductServices;
