import React, { useEffect,useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import "./Header.css";
import Logo from'./../../Assests/images/logo.png';
import bhaijeeHumbergerIcon from './../../Assests/images/bhaijeeHumbergerIcon.png';

function Header() {
 
    // if(window.location.href == 'http://cmweb:683')
    // {      
    //   //alert(window.location.href);
    //   var url ="http://192.168.50.81:8087/EkycController/ekyc_data";
    // }
    // else if(window.location.href == 'http://bhaijee.uat.cmots.com')
    // {
    //   var url ="http://bhaijee.cmots.in/EkycController/ekyc_data";
    // }

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return() => {
      window.removeEventListener("scroll", isSticky);
    };
  });
  const isSticky = (e) =>{
    const header = document.querySelector(".header-section");
    const scrooltop = window.scrollY;
    scrooltop >= 100 ? header.classList.add("is-sticky")
    : header.classList.remove("is-sticky");
  }
  // function btnClick() {
  //   const showOnMobile = document.querySelector(".showOnMobile");
  //   showOnMobile.classList.add("Active");
  // }
  // function closeMobileM() {
  //   const showOnMobile = document.querySelector(".showOnMobile.Active");
  //   showOnMobile.classList.remove("Active");
  // }

  return (
    <>
    <div className="header-section">
      <Container fluid>
        <Row className="align-items-center">
          <Col xs={5} lg={3} className="pt-1"> 
            <Nav.Link href="/">
              <img src={Logo} className="img-fluid"/>
            </Nav.Link>
          </Col>
        
          <Col xs={5} lg={9} className="DesktopMenuHide"> 
          <Nav className="menubody">
                  {/* <Nav.Item>
                    <Nav.Link className="menutextcolor" href="/">
                      Home
                    </Nav.Link>
                  </Nav.Item> */}
                  
                  <Nav.Item>
                    <Nav.Link className="menutextcolor" href="/aboutus">
                      About Us
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="menutextcolor" href="/productservices">
                      Product & Services 
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="menutextcolor" href="/downloads">
                    Downloads
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="menutextcolor" href="/contactus">
                      Contact Us
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="menutextcolor" target="_blank" href="https://webbkp.bhaijee.com:8443/capexweb/capexweb/">
                    Back Office 
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="menutextcolor" target="_blank" href="https://evoting.cdslindia.com/Evoting/EvotingLogin">
                      E-Voting
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="menutextcolor" target="_blank" href="https://bhaijee.webappreports.com/login.php ">
                     Dashboard
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="menutextcolor" target="_blank" href="https://bhaijee.webappreports.com/applyipo/">
                     IPO
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="margin_bott-2">
                    <Nav.Link className="menutextcolor demat-acc" target="_blank" href='http://bhaijee.cmots.in/EkycController/ekyc_data'>
                     Open Demat Account 
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
          </Col>
          <Col xs={2} className="MobileMenuShow">
          <Nav className="HumberIcon"> 
          <NavDropdown id="nav-dropdown-dark-example">
                {/* <NavDropdown.Item href="/"> Home </NavDropdown.Item> */}
                <NavDropdown.Item href="/aboutus">  About Us </NavDropdown.Item>
                <NavDropdown.Item href="/productservices"> Product & Services </NavDropdown.Item>
                <NavDropdown.Item href="/downloads"> Downloads </NavDropdown.Item>
                <NavDropdown.Item href="/contactus"> Contact Us </NavDropdown.Item>
                <NavDropdown.Item href="https://webbkp.bhaijee.com:8443/capexweb/capexweb/" target="_blank"> Back Office </NavDropdown.Item>
                <NavDropdown.Item target="_blank" href="https://evoting.cdslindia.com/Evoting/EvotingLogin">  E-Voting </NavDropdown.Item>
                <NavDropdown.Item target="_blank" href="https://bhaijee.webappreports.com/login.php">  Dashboard </NavDropdown.Item>
                <NavDropdown.Item target="_blank" href="https://bhaijee.webappreports.com/applyipo/">  IPO </NavDropdown.Item>
                
              </NavDropdown>
          </Nav>
            {/* <img src={bhaijeeHumbergerIcon} className="img-fluid" /> */}
            </Col>
        </Row>
      </Container>
    </div>
    </>
  );
}

export default Header;
