import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import './UsefulLink.css'

const UsefulLink = () => {
    return (
        <>
            <Container>
                <Row>
                    <Col xs={12} className="ContactLeadfrom contact-table useful-link-sc">
                        <div className="ContactLeadformHeading">
                            Useful Link
                        </div>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Sr No</th>
                                        <th>Heading</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Online Grievance Redressal (ODR) </td>
                                        <td><span><a href='https://smartodr.in/login' target='_blank'>Click here</a></span></td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>Client collateral details (NCL)</td>
                                        <td><span><a href='https://investorhelpline.nseindia.com/ClientCollateral/welcomeCLUser' target='_blank'>Click here</a></span></td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>Client collateral details (MCXCCL)</td>
                                        <td><span><a href='https://clientreports.mcxccl.com/' target='_blank'>Click here</a></span></td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>Client collateral details (ICCL)</td>
                                        <td><span><a href='https://bseplus.bseindia.com/' target='_blank'>Click here</a></span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default UsefulLink
