import React from "react";
import {Container, Row, Col} from 'react-bootstrap';
import './contactsectwo.css';

function contactsectwo (){
    return(
        <>
        <Container>
            <Row>
                <Col xs={12} className="ContactLeadfrom contact-table">
                    <div className="ContactLeadformHeading"> 
                    Investor Grievance Escalation Matrix
                    </div>
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Details of</th>
                                    <th>Contact Person</th>
                                    <th>Address</th>
                                    <th>Contact No.</th>
                                    <th>Email Id</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Customer care</td>
                                    <td>Meena Satija</td>
                                    <td>03rd Floor, Building No. 14, Phase-I, Central Market, Ashok Vihar, Delhi-110052</td>
                                    <td>011-47475508 ( Mon- Fri : 9.30 am - 6.00 pm )</td>
                                    <td><a href="mailto:limit@bhaijee.com">limit@bhaijee.com</a></td>
                                </tr>
                                <tr>
                                    <td>Head of Customer care</td>
                                    <td>Rahul Bhandari</td>
                                    <td>03rd Floor, Building No. 14, Phase-I, Central Market, Ashok Vihar, Delhi-110052</td>
                                    <td>011-47475507 ( Mon- Fri : 9.30 am - 6.00 pm )</td>
                                    <td><a href="mailto:info@bhaijee.com">info@bhaijee.com</a></td>
                                </tr>
                                <tr>
                                    <td>Compliance Officer</td>
                                    <td>Rahul Bhandari</td>
                                    <td>03rd Floor, Building No. 14, Phase-I, Central Market, Ashok Vihar, Delhi-110052</td>
                                    <td>011-47475507 ( Mon- Fri : 9.30 am - 6.00 pm )</td>
                                    <td><a href="mailto:info@bhaijee.com">info@bhaijee.com</a></td>
                                </tr>
                                <tr>
                                    <td>CEO</td>
                                    <td>Satish Kumar Gupta</td>
                                    <td>03rd Floor, Building No. 14, Phase-I, Central Market, Ashok Vihar, Delhi-110052</td>
                                    <td>011-47475510 ( Mon- Fri : 9.30 am - 6.00 pm )</td>
                                    <td><a href="mailto:satish.gupta@bhaijee.com">satish.gupta@bhaijee.com</a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="contact-line">
                        <p>In absence of a response/complaint not addressed to your satisfaction, you may lodge a complaint with:</p>
                        <ul>
                            <li>SEBI at <a href="https://scores.sebi.gov.in/" target="_blank">https://scores.sebi.gov.in/</a></li>
                            <li>Exchange at <a href="https://investorhelpline.nseindia.com/NICEPLUS/" target="_blank">https://investorhelpline.nseindia.com/NICEPLUS/</a></li>
                        </ul>
                        <p>Please quote your Service Ticket/Complaint Ref No. while raising your complaint at SEBI SCORES/Exchange portal.</p>
                    </div>
                </Col>
            </Row>
        </Container>
       <Container className="contactsectwobg">
        <Row>
            <Col md={6} xs={12} className="ContactDetailHeading">  Details of Compliance Officer </Col>
            <Col md={6} xs={12} className="ContactSectwodetails">   <ul>
                <li> Name : Rahul Bhandari</li>
                <li> <span> Contact No.</span>  <br />              
                011-47475507; +91-9560316317
                </li>
                <li> <span> Email ID </span>    <br />             
                <a href="mailto:info@bhaijee.com">   info@bhaijee.com </a>
                </li>
            </ul> </Col>    
        
        </Row>
       </Container>
        </>
    )
}
export default contactsectwo;