import React from "react";
import Contactsecone from "./contactsecone/contactsecone";
import Contactsectwo from "./contactsectwo/Contactsectwo";
import Contactthree from "./contactthree/contactthree";

function ContactUs(){
    return(
        <>
       <Contactsecone/>
       <Contactsectwo/>
       <Contactthree/>
        </>
    )
}
export default ContactUs;