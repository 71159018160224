import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import depository from '../../../Assests/images/depository.png';
import './aboutsectiontwo.css';

const DStext ="Depository Services (CDSL)";
const abtsectwolineone ="Opening of Demat Account";
const abtsectwolinetwo =" For Demat Account related Services (e.g. Demat, Transfer, Pledging, Freezing and Remat of Securities)";

function aboutsectiontwo(){
    return(
        <>
      <Container>
        <Row> 
            <Col md={6} xs={12}>
            <div> <img src={depository} className="img-fluid" /> </div>
          
            </Col>
            <Col md={6} xs={12}>
            <div className="AboutsecundSecText"> 
            <ul>
                <li> {DStext} </li>
                <li> {abtsectwolineone}</li>
                <li> {abtsectwolinetwo} </li>
                    
            </ul> </div>
            </Col>
        </Row>
      </Container>
        </>
    )

}
export default aboutsectiontwo;