import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import aboutone from "../../../Assests/images/aboutone.png";
import './AboutSectionone.css';
const Profileheading ="PROFILE OF THE ";
const Companyword ="COMPANY";
const Aboutdiscription ="Bhaijee Portfolio Private Limited is a Stock Broking Company which is engaged in providing broking services to their clients. It refer to the activity of buying and/or selling of shares. By enrolling as a client with Bhaijee, a client may avail the opportunity of trading on any of these Stock Exchanges." ;
const redbutton ="Read More";

function aboutSectionone (){
    return(
        <>
        <Container className="aboutseconetoppadd">
            <Row>
                <Col lg={8} sm={7}>
                  <div className="AboutMain-heading"> {Profileheading}  <span>{Companyword} </span> </div>  
                  <div className="About-Main-discription"> {Aboutdiscription} </div>  
<div className="About-CTA"> <button> {redbutton}</button></div>        
                </Col>
                <Col lg={4} sm={5}>
               <img src={aboutone} className="img-fluid" />
                </Col>
            </Row>
        </Container>
        </>
    )
}
export default aboutSectionone;