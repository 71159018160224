import {React} from "react";
import{Col, Container, Row,} from'react-bootstrap';
import equities from "./../../../Assests/images/equities.png";
import derivatives from './../../../Assests/images/derivatives.png';
import commodities from './../../../Assests/images/commodities.png';
import currency from './../../../Assests/images/currency.png';
import ipo from "./../../../Assests/images/ipo.png";
import ProductDepository from "./../../../Assests/images/ProductDepository.png";
import './Services.css';



export default function Services(){
   return(
<>
<Container>
      
 <Row className="align-items-center">
       <Col ms={12} lg={6}> <img src={equities} className="img-fluid" /> </Col>
       <Col ms={12} lg={6}> <p className="AllServicesBox">
        <span> Equities </span>
        Investing in shares or stock market is the best route to long-term wealth accumulation. 
        However, it can also be a very risky proposition due to high risk-return trade-off prevalent 
        in the stock market. Hence, it is more appropriate to take help of an experienced and trustworthy 
        expert who will guide you as to when, where and how to invest. Bhaijee provides guidance in the 
        stock market with suitable trading solutions and value-added tools and services to enhance your 
        trading experience.
        </p> </Col>
         </Row>
<Row className="align-items-center">
    <Col ms={12} lg={6}> <img src={derivatives} className="img-fluid" /> </Col>
    <Col ms={12} lg={{order:'first'}}> <p className="AllServicesBox">
        <span> Derivatives </span>
        A derivative is an instrument whose value is derived from the value of one or more underlying,
         which can be commodities, precious metals, currency, bonds, stocks, stocks indices, etc. 
         Four most common examples of derivative instruments are Forwards, Futures, Options and Swaps.


        </p> </Col>
         </Row> 
<Row className="align-items-center">
    <Col ms={12} lg={6}> <img src={commodities} className="img-fluid" /> </Col>
    <Col ms={12} lg={6}> <p className="AllServicesBox">
        <span> Commodities </span>
        Commodities Derivative market has emerged as a new avenue for investors. Today, Commodities have 
        evolved as the next best option after stocks and bonds for diversifying the portfolio. Based on the 
        fundamentals of demand and supply, Commodities form a separate asset class offering investors, 
        arbitrageurs and speculators immense potential to earn returns.

        </p> </Col>
         </Row> 
<Row className="align-items-center">
    <Col ms={12} lg={6}> <img src={currency} className="img-fluid" /> </Col>
    <Col ms={12} lg={{order:'first'}}> <p className="AllServicesBox">
        <span> Currency </span>
        Currency derivatives is used to hedge the Forex risk by bank, importers and exporters. Currency 
        Derivatives are Future and Options contracts which you can buy or sell specific quantity of a 
        particular currency pair at a future date. It is similar to the Stock Futures and Options but the 
        underlying happens to be currency pair (i.e. USDINR, EURINR, JPYINR OR GBPINR) instead of Stocks. 
        It is not only a good investment option but there are multiple benefits of currency trading in 
        India as well.   </p> </Col>
         </Row> 
 <Row className="align-items-center">
    <Col ms={12} lg={6}> <img src={ipo} className="img-fluid" /> </Col>
    <Col ms={12} lg={6}> <p className="AllServicesBox">
        <span> IPO </span>
        Initial public offering is the process by which a private company can go public by sale of its 
        stocks to general public. It could be a new or an old company which decides to be listed on an 
        exchange and hence goes public. Companies can raise equity capital with the help of an IPO by 
        issuing new shares to the public or the existing shareholders can sell their shares to the public 
        without raising any fresh capital. From an investor point of view, IPO gives a chance to buy shares 
        of a company, directly from the company at the price of their choice (In book build IPO's).
  </p> </Col>
         </Row> 
<Row className="align-items-center">
    <Col ms={12} lg={6}> <img src={ProductDepository} className="img-fluid" /> </Col>
    <Col ms={12} lg={{order:'first'}}> <p className="AllServicesBox">
        <span> Depository Participant Services </span>
        Bhaijee is registered as Depository Participant with CDSL. Bhaijee offers services related to demat 
        accounts to our clients like demat, Transfer, Pledging, Freezing and Remat of securities. Bhaijee 
        offers services of trading and depository under one roof and experience efficient, risk-free and 
        prompt depository service.

  </p> </Col>
         </Row> 
         
         </Container>
</>

    )
}