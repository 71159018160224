import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../whyBhaijee/WhyBhaijee";
import "./ClientRegistration.css";
import {BsCloudDownloadFill} from 'react-icons/bs';

function ClientRegistration(){
    return(
        <>
            <section className="client-reg-sc">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <div class="why-us">
                                <h2>CLIENT REGISTRATION DOCUMENTS (Equity)</h2>
                            </div>
                            <div className="client-sc equity-client-forms">
                                <p>Download Client Registration Documents (Rights & Obligations, Risk Disclosure Document, Do's & Don't's) in Vernacular Language :</p>
                                <div className="client-box-main">
                                    <div className="client-box-inner">
                                        <a href="https://static.nseindia.com//s3fs-public/inline-files/Assamese.zip"><BsCloudDownloadFill></BsCloudDownloadFill> Assamese<br />অসমীয়া</a>
                                    </div>
                                    <div className="client-box-inner">
                                        <a href="https://static.nseindia.com//s3fs-public/inline-files/Bengali.zip"><BsCloudDownloadFill></BsCloudDownloadFill> Bengali<br />বাঙালি</a>
                                    </div>
                                    <div className="client-box-inner">
                                        <a href="https://static.nseindia.com//s3fs-public/inline-files/Gujrati.zip"><BsCloudDownloadFill></BsCloudDownloadFill> Gujrati<br />ગુજરાતી</a>
                                    </div>
                                    <div className="client-box-inner">
                                        <a href="https://static.nseindia.com//s3fs-public/inline-files/Hindi.zip"><BsCloudDownloadFill></BsCloudDownloadFill> Hindi<br />हिंदी</a>
                                    </div>
                                    <div className="client-box-inner">
                                        <a href="https://static.nseindia.com//s3fs-public/inline-files/Kanada.zip"><BsCloudDownloadFill></BsCloudDownloadFill> Kanada<br />ಕನ್ನಡ</a>
                                    </div>
                                    <div className="client-box-inner">
                                        <a href="https://static.nseindia.com//s3fs-public/inline-files/Kashmiri.zip"><BsCloudDownloadFill></BsCloudDownloadFill> Kashmiri<br />كٲشُر</a>
                                    </div>
                                    <div className="client-box-inner">
                                        <a href="https://static.nseindia.com//s3fs-public/inline-files/Konkani.zip"><BsCloudDownloadFill></BsCloudDownloadFill> Konkani<br />कोंकणी</a>
                                    </div>
                                    <div className="client-box-inner">
                                        <a href="https://static.nseindia.com//s3fs-public/inline-files/Malyalam.zip"><BsCloudDownloadFill></BsCloudDownloadFill> Malyalam<br />മലയാളം</a>
                                    </div>
                                    <div className="client-box-inner">
                                        <a href="https://static.nseindia.com//s3fs-public/inline-files/Marathi.zip"><BsCloudDownloadFill></BsCloudDownloadFill> Marathi<br />मराठी</a>
                                    </div>
                                    <div className="client-box-inner">
                                        <a href="https://static.nseindia.com//s3fs-public/inline-files/Oriya.zip"><BsCloudDownloadFill></BsCloudDownloadFill> Oriya<br />ଓଡ଼ିଆ</a>
                                    </div>
                                    <div className="client-box-inner">
                                        <a href="https://static.nseindia.com//s3fs-public/inline-files/Punjabi.zip"><BsCloudDownloadFill></BsCloudDownloadFill> Punjabi<br />ਪੰਜਾਬੀ ਦੇ</a>
                                    </div>
                                    <div className="client-box-inner">
                                        <a href="https://static.nseindia.com//s3fs-public/inline-files/Sindhi.zip"><BsCloudDownloadFill></BsCloudDownloadFill> Sindhi<br />سنڌي</a>
                                    </div>
                                    <div className="client-box-inner">
                                        <a href="https://static.nseindia.com//s3fs-public/inline-files/Tamil.zip"><BsCloudDownloadFill></BsCloudDownloadFill> Tamil<br />தமிழ்</a>
                                    </div>
                                    <div className="client-box-inner">
                                        <a href="https://static.nseindia.com//s3fs-public/inline-files/Telegu.zip"><BsCloudDownloadFill></BsCloudDownloadFill> Telegu<br />తెలుగు</a>
                                    </div>
                                    <div className="client-box-inner">
                                        <a href="https://static.nseindia.com//s3fs-public/inline-files/Urdu.zip"><BsCloudDownloadFill></BsCloudDownloadFill> Urdu<br />اردو</a>
                                    </div>
                                </div>
                                <p><b>Note:</b> "This document is a translated version of the client registration documents in English and is being provided in vernacular language to facilitate better understanding by the investors. In case of any ambiguity, the contents of the English version would prevail."</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div class="why-us">
                                <h2>CLIENT REGISTRATION DOCUMENTS (Commodity) - <a href="https://www.mcxindia.com/docs/default-source/membership/vernacular-language/kyc_documents.zip?sfvrsn=2"> English</a></h2>
                            </div>
                            <div className="client-sc">
                                <p>Download Client Registration Documents (Rights & Obligations, Risk Disclosure Document, Do's & Don't's) in Vernacular Language :</p>
                                <div className="client-box-main">
                                    <div className="client-box-inner">
                                        <a href="https://www.mcxindia.com/docs/default-source/membership/vernacular-language/assamese3cb1914657fb64e3bdfdff00007acb35.zip?sfvrsn=2"><BsCloudDownloadFill></BsCloudDownloadFill> Assamese<br />অসমীয়া</a>
                                    </div>
                                    <div className="client-box-inner">
                                        <a href="https://www.mcxindia.com/docs/default-source/membership/vernacular-language/bengalie4b2914657fb64e3bdfdff00007acb35.zip?sfvrsn=2"><BsCloudDownloadFill></BsCloudDownloadFill> Bengali<br />বাঙালি</a>
                                    </div>
                                    <div className="client-box-inner">
                                        <a href="https://www.mcxindia.com/docs/default-source/membership/vernacular-language/gujarati52b3914657fb64e3bdfdff00007acb35.zip?sfvrsn=2"><BsCloudDownloadFill></BsCloudDownloadFill> Gujrati<br />ગુજરાતી</a>
                                    </div>
                                    <div className="client-box-inner">
                                        <a href="https://www.mcxindia.com/docs/default-source/membership/vernacular-language/hindic0b1914657fb64e3bdfdff00007acb35.zip?sfvrsn=2"><BsCloudDownloadFill></BsCloudDownloadFill> Hindi<br />हिंदी</a>
                                    </div>
                                    <div className="client-box-inner">
                                        <a href="https://www.mcxindia.com/docs/default-source/membership/vernacular-language/kannadafab2914657fb64e3bdfdff00007acb35.zip?sfvrsn=2"><BsCloudDownloadFill></BsCloudDownloadFill> Kanada<br />ಕನ್ನಡ</a>
                                    </div>
                                    <div className="client-box-inner">
                                        <a href="https://www.mcxindia.com/docs/default-source/membership/vernacular-language/kashmiri68b3914657fb64e3bdfdff00007acb35.zip?sfvrsn=2"><BsCloudDownloadFill></BsCloudDownloadFill> Kashmiri<br />كٲشُر</a>
                                    </div>
                                    <div className="client-box-inner">
                                        <a href="https://www.mcxindia.com/docs/default-source/membership/vernacular-language/konkani6bb2914657fb64e3bdfdff00007acb35.zip?sfvrsn=2"><BsCloudDownloadFill></BsCloudDownloadFill> Konkani<br />कोंकणी</a>
                                    </div>
                                    <div className="client-box-inner">
                                        <a href="https://www.mcxindia.com/docs/default-source/membership/vernacular-language/malayalam10b3914657fb64e3bdfdff00007acb35.zip?sfvrsn=2"><BsCloudDownloadFill></BsCloudDownloadFill> Malyalam<br />മലയാളം</a>
                                    </div>
                                    <div className="client-box-inner">
                                        <a href="https://www.mcxindia.com/docs/default-source/membership/vernacular-language/marathi7eb3914657fb64e3bdfdff00007acb35.zip?sfvrsn=2"><BsCloudDownloadFill></BsCloudDownloadFill> Marathi<br />मराठी</a>
                                    </div>
                                    <div className="client-box-inner">
                                        <a href="https://www.mcxindia.com/docs/default-source/membership/vernacular-language/oriya96b2914657fb64e3bdfdff00007acb35.zip?sfvrsn=2"><BsCloudDownloadFill></BsCloudDownloadFill> Oriya<br />ଓଡ଼ିଆ</a>
                                    </div>
                                    <div className="client-box-inner">
                                        <a href="https://www.mcxindia.com/docs/default-source/membership/vernacular-language/punjabi26b3914657fb64e3bdfdff00007acb35.zip?sfvrsn=2"><BsCloudDownloadFill></BsCloudDownloadFill> Punjabi<br />ਪੰਜਾਬੀ ਦੇ</a>
                                    </div>
                                    <div className="client-box-inner">
                                        <a href="https://www.mcxindia.com/docs/default-source/membership/vernacular-language/sindhi94b3914657fb64e3bdfdff00007acb35.zip?sfvrsn=2"><BsCloudDownloadFill></BsCloudDownloadFill> Sindhi<br />سنڌي</a>
                                    </div>
                                    <div className="client-box-inner">
                                        <a href="https://www.mcxindia.com/docs/default-source/membership/vernacular-language/tamilbdb2914657fb64e3bdfdff00007acb35.zip?sfvrsn=2"><BsCloudDownloadFill></BsCloudDownloadFill> Tamil<br />தமிழ்</a>
                                    </div>
                                    <div className="client-box-inner">
                                        <a href="https://www.mcxindia.com/docs/default-source/membership/vernacular-language/telegu3cb3914657fb64e3bdfdff00007acb35.zip?sfvrsn=2"><BsCloudDownloadFill></BsCloudDownloadFill> Telegu<br />తెలుగు</a>
                                    </div>
                                    <div className="client-box-inner">
                                        <a href="https://www.mcxindia.com/docs/default-source/membership/vernacular-language/urduaab3914657fb64e3bdfdff00007acb35.zip?sfvrsn=2"><BsCloudDownloadFill></BsCloudDownloadFill> Urdu<br />اردو</a>
                                    </div>
                                </div>
                                <p><b>Note:</b> "This document is a translated version of the client registration documents in English and is being provided in vernacular language to facilitate better understanding by the investors. In case of any ambiguity, the contents of the English version would prevail."</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
export default ClientRegistration;