import React from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import "./AboutUsHome.css";
function AboutUsHome() {
  return (
    <>
      <section className="about">
        <Container>
          <Row>
            <Col xs={12} md={12} lg={12}>
              <h2>About Us</h2>
              <h5>
                Bhaijee was incorporated on 26th April, 1995 with an objective
                of eliminating issues faced by investors and traders in terms of
                support, technology, cost etc. Bhaijee is considered as one of
                the prominent stock broker and a Proprietary trading house. Our
                experience over decades has helped us to integrate our knowledge
                and expertise in the broking industry.By enrolling as a client
                with Bhaijee, a client may avail the opportunity of trading on
                any of these Stock Exchanges.
              </h5>

              <p>
                The company continues to sustain the ethical values,
                reliability, work transparency and best services at a remarkable
                pace, precision and swiftness. We have enhanced client
                engagement and experience through blending of application of
                technology. We at Bhaijee thus assist investors to focus on
                wealth creation and avoid speculation. We take pride in our
                endeavours while guiding investors in finding good investment
                opportunities.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default AboutUsHome;
