import React from "react";
import ProductServicesMainBanner from"./ProductServicesMainBanner/ProductServicesMainBanner";
import Services from "./Services/Services";

export default function ProductServices(){
    return(
        <>

      <ProductServicesMainBanner />
      <Services />
        </>
    )
}