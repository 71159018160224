import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../InactiveClient/InactiveClient";
function Advisory(){
    return(
        <>
            <section className="static-bg static-pg inactive-sc">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className="static-heading">
                                <h2>Advisory – KYC Compliance</h2>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <p>1. All investors are requested to take note that 6 KYC attributes i.e. Name, PAN, Address, Mobile Number, Email id and Income Range have been made mandatory. Investors availing custodian services will be additionally required to update the custodian details.</p>
                            <p>2. The last date to update KYC is on or before 30th June, 2022.</p>
                            <p>Thereafter non-compliant trading accounts will be blocked for trading by the Exchange.</p>
                            <p>3. The non-compliant demat accounts will be frozen for debits by Depository Participant or Depository.</p>
                            <p>4. On submission of the necessary information to the stockbroker and updation of the same by the stockbroker in the Exchange systems and approval by the Exchange, the blocked trading accounts shall be unblocked by the Exchange on T+1 trading day.</p>
                            <p>5. The demat account shall be unfreezed once the investor submits the deficient KYC details and the same is captured by the depository participant in the depository system.</p>
                            <p>6. To ensure smooth settlement of trades, the investors are requested to ensure that both the trading and demat accounts are compliant with respect to the KYC requirement.</p>
                            <p>7. The investors are hereby requested to comply with the regulatory guidelines issued by Exchanges and Depositories from time to time with regard to KYC compliance and related requirements.</p>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
export default Advisory;