import React from "react";
import {Container, Row, Col} from 'react-bootstrap';
import './aboutsectionfour.css';
import vision from './../../../Assests/images/vision.png';
const VMheading =" Vision and Mission ";
const VMtext ="To provide fair and good services to small, retail, HNI and Corporate investors.Providing Integrated Stock Market Services with great Transparency, Trust, and Confidence.";


function aboutsectionfour (){
    return(
        <>
        <Container fluid className="abtsecforubg"> 
        <Container >
            <Row>
            <Col sm={12} md={4}> <img src={vision} className="img-fluid" /> </Col>
            <Col sm={12} md={8}> <div className="vmtext"> 
            <ul>
            <li>{ VMheading}</li>
            <li> {VMtext} </li>
            </ul>
            </div> </Col>
            </Row>
        </Container>
        </Container>
        </>
    )
}
export default aboutsectionfour;