//import logo from "./logo.svg";
import React from "react";
import "./App.css";
import "./index.css";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
// import HomePage from "./Components/HomePage/HomePage";
import {Route, Routes } from 'react-router-dom';
import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";
// import TestComponents from "./Components/TestComponents/Testcomponents"
// import Test2 from "./Components/Test2/Test2"
import HomePage from "./Components/HomePage/HomePage";
import AboutUs from './Components/AboutUs/AboutUs';
import ContactUs from "./Components/Contactus/ContactUs";
import ProductServices from "./Components/ProductServices/ProductServices";
import Downloads from "./Components/Downloads/Downloads";
import InactiveClient from "./Components/InactiveClient/InactiveClient";
import PrivacyPolicy from "./Components/PrivacyPolicy/PrivacyPolicy";
import Disclaimer from "./Components/Disclaimer/Disclaimer";
import Pmla from "./Components/PMLA/Pmla";
import Advisory from "./Components/Advisory/Advisory";
import BankAccount from "./Components/BankAccount/BankAccount";
import InvestorCorner from './Components/InvestorCorner/InvestorCorner';
import UsefulLink from './Components/UsefulLink/UsefulLink'

function App() {
  return (
    
    <div className="App">
      <Header />
      {/* <ProductServices /> */}
        <Routes>
          <Route path="/" element={<HomePage/>} exact/>
          {/* <Route path="/test2" element={<Test2/>}/> */}
          <Route path="/aboutus" element={<AboutUs/>}></Route>         
          <Route path="/contactus" element={<ContactUs/>}></Route>
          <Route path="/productservices" element={<ProductServices/>}></Route>
          <Route path="/downloads" element={<Downloads/>}></Route>
          <Route path="/inactiveClient" element={<InactiveClient/>}></Route>
          <Route path="/privacypolicy" element={<PrivacyPolicy/>}></Route>
          <Route path="/disclaimer" element={<Disclaimer/>}></Route>
          <Route path="/pmla" element={<Pmla/>}></Route>
          <Route path="/advisory" element={<Advisory/>}></Route>
          <Route path="/bankaccount" element={<BankAccount/>}></Route>
          <Route path="/investorCorner" element={<InvestorCorner />}></Route>
          <Route path="/usefullink" element={<UsefulLink/>}></Route> 
        </Routes>
      <Footer />

{/* 
      <Router>
     <div>
        <Routes>
          <Route exact path="/" element={<HomePage/>}/>
          <Route exact path="/AboutUs" element={<AboutUs/>}/>
          <Route exact path="/Contactus" element={<Contactus/>}/>
        </Routes>
     </div>
    </Router> */}
      
      {/* <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route path="/Contactus" element={<Contactus />} />
        <Route path="/AboutUs" element={<AboutUs />} />
      </Route>
    </Routes>
  </BrowserRouter> */}

      {/* <HomePage /> */}
      {/* <Contactus /> */}
      {/* <AboutUs /> */}

  
     
    </div> 
    
  );
}

export default App;
