import React from "react";
import {Container, Row, Col, Form, Button} from 'react-bootstrap';
import './contactsecone.css';


function contactsecone(){
    return(
        <>
       <Container fluid  className="aboutseconebg"> 
       <Container >
        <Row>
            <Col xs={12} className="leftrifgrpadd0"> 
            <div className="col-lg-8 col-md-9 col-xs-12">
            <div className="ContactCompanyName"> <h1> Bhaijee Portfolio Private Limited </h1></div>
            <div className="contactRegisterAddr"> 
                <ul>
                    <li>
                   Registered Address 
                    </li>
                    <li>
                    03rd Floor, Building No. 14, Central Market, Ashok Vihar, Delhi-110052
                    </li>
                    <li> 
                   <span> Phone  <br/>  </span> +911147475555 
                    </li>
                    <li> <span> Investor Grievance </span> <br/> 
                        contact@bhaijee.com </li>
                </ul>
            </div>

            <div className="contactRegisterAddr"> 
                <ul>
                    <li className="topheadinPass">
                    Corporate Office 
                    </li>
                    <li>
                    03rd Floor, Building No 14, Central Market, Ashok Vihar, Delhi-110052
                    </li>
                    <li> 
                   <span>  Mobile  <br/>  </span> +919560566117
                    </li>
                    <li> <span> Investor Grievance </span> <br/> 
                        contact@bhaijee.com </li>
                </ul>
            </div>
            </div>
            <div className="col-12 about-hero-imgBlankPass"> </div>
            </Col>
        </Row>
       </Container>
       </Container>
       <Container>
        <Row>
        <Col xs={12} className="ContactLeadfrom">
            <div className="col-12 ContactLeadformHeading"> 
            Bhaijee Message <span> Please fill the bellow form so we get revert you for your query raised.</span>
            </div>
            <div className="col-12"> 
            <Form>
      <Form.Group className="mb-3 Cont-lead-aliment" controlId="formBasicEmail">       
        <Form.Control type="text" placeholder="Full Name" />
        <Form.Control type="text" placeholder="Email id" />
        <Form.Control type="text" placeholder="Phone No." />
        <Form.Control type="text" placeholder="Type query here.. " />
      </Form.Group>
      <Button type="submit" className="SednButton">
        Submit
      </Button>
       </Form>
       </div>

            </Col>
        </Row>
       </Container>
       
        </>
    )
}
export default contactsecone;