import React from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import "./WelcomeSection.css";
function WelcomeSection() {
  return (
    <>
      <section className="welcome">
        <Container>
          <Row>
            <Col xs={12} md={10} lg={8}>
              <div className="intro-alpha">
                <h1 className="some-class">Introduction</h1>
                <p>
                  A Trading Company which provides broking services to
                  Corporates and Individuals. Their objective is to provide
                  services which makes trading easier for clients. Company is
                  also committed towards customer satisfaction with their
                  growth.
                </p>
                <p>
                  Our logo in blue symbolizes Knowledge. Applied knowledge leads
                  to creation of wealth for our investors. Our Logo represents
                  power and prosperity.
                </p>
                <a type="submit" className="btn" target="_blank" href='http://bhaijee.cmots.in/EkycController/ekyc_data'>
                  Open Demat Account
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default WelcomeSection;
