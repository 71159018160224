import React from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import "./WhyBhaijee.css";
import img1 from "../../../Assests/images/whyBhaijee.png";
// import {navigate, useNavigate} from "react-router-dom";
// import AboutUs from "../../AboutUs/AboutUs";
function WhyBhaijee() {
  // let navigate = useNavigate();
  // const readmore = () => {
  //   let path = "../AboutUs";
  //   navigate(path);
  // }
  return (
    <>
      <section className="why-us">
        <Container>
          <Row>
            <Col xs={12} md={4} lg={4}>
              <img className="img-fluid" src={img1} />
            </Col>
            <Col xs={12} md={8} lg={8}>
              <div className="why-us-beta">
                <h2>
                  <span>Why</span>Bhaijee
                </h2>
                <p>
                  Our Greatest Asset is the Trust that builds up relationship
                  between us and our clients that makes us Stronger. Our Aim and
                  Commitment is to provide quality services to all our clients
                  and to suggest an investment products that’s best suits a
                  client to achieve its financial goal. Our Products and
                  Services are ready to meet the financial requirements of the
                  Customers. Experience of Bhaijee will deliver all the quality
                  services to the clients as per their requirements.
                </p>
                {/* <Button type="submit" onClick={readmore}>
                  Read More
                </Button> */}
                <a href="/AboutUs"  className="read-more">Read More</a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default WhyBhaijee;
