import React from "react";
import { Container, Col, Row} from "react-bootstrap";
import "./InactiveClient.css";
function InactiveClient(){
    return(
        <>
            <section className="static-bg static-pg inactive-sc">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className="static-heading">
                                <h2>Inactive Client policy </h2>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <p>Client account will be considered as inactive if the client does not trade for period of six month. Calculation will be done at the beginning of every month and those clients who have not traded even a single time will be considered as inactive, the shares/credit ledger balance if any will be transferred to the client within one week of the identifying the client as inactive. The client has to make written request for reactivation of their account. </p>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
export default InactiveClient;