import React from 'react';
import WelcomeSection from "./WelcomeSection/WelcomeSection";
import ProductServices from "./ProductServices/ProductServices";
import WhyBhaijee from "./whyBhaijee/WhyBhaijee";
import History from "./History/History";
import AboutUsHome from './AboutUsHome/AboutUsHome';
import ClientRegistration from './ClientRegistration/ClientRegistration';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import {AiFillCloseCircle} from 'react-icons/ai';
import "./HomePage.css";

function HomePage(){
    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);

    return(
        <>
        <div>

            <Modal show={show} onHide={handleClose}  className="complaint-modal risk-modal">
                
                <Modal.Body >
                    <div onClick={handleClose} className="text-right">
                        <AiFillCloseCircle></AiFillCloseCircle>
                    </div>
                    <div>
                        <h3>RISK DISCLOSURES ON DERIVATIVES</h3>
                        <ul>
                            <li>9 out of 10 individual traders in equity Futures and Options Segment, incurred net losses.</li>
                            <li>On an average, loss makers registered net trading loss close to ₹ 50,000.</li>
                            <li>Over and above the net trading losses incurred, loss makers expended an additional 28%
                            of net trading losses as transaction costs.</li>
                            <li>Those making net trading profits, incurred between 15% to 50% of such profits as
                            transaction cost.</li>
                            <li><a href='https://www.sebi.gov.in/reports-and-statistics/research/jan-2023/study-analysis-of-profit-and-loss-of-individual-traders-dealing-in-equity-fando-segment_67525.html' target='_blank'>SEBI study dated January 25, 2023 on “Analysis of Profit and Loss of Individual Traders dealing in equity Futures and Options (F&O) Segment”, wherein Aggregate Level findings are based on annual Profit/Loss incurred by individual traders in equity F&O during FY 2021-22.</a></li>
                        </ul>
                    </div>
                </Modal.Body>
               
            </Modal>
        </div>
        <WelcomeSection />
        <AboutUsHome />
        <ProductServices />
        <WhyBhaijee />
        <History /> 
        <ClientRegistration />
        </>

    )
}
export default HomePage;