import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import './contactthree.css';

function contactthree() {
    return(

        <>
        <Container className="MapTopBott-Margin">
            <Row>           
            <div class="mapouter">
            <div class="gmap_canvas">
                <iframe class="gmap_iframe" width="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=1366&amp;height=400&amp;hl=en&amp;q=Ashok Vihar, Delhi-110052&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe><a href="https://www.kokagames.com/fnf-friday-night-funkin-mods/">FNF Mods</a></div>
   
                </div>

            </Row>
        </Container>
        </>
    )

}
export default contactthree;