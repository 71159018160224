import  React from 'react';
import AboutSectionone from './AboutSectionone/AboutSectionone';
import Aboutsectiontwo from "./AboutSectiontwo/aboutsectiontwo";
import Aboutsectionfour from './AboutSectionfour/aboutsectionfour';
import Aboutsectionthree from './AboutSectionthree/aboutsectionthree';


function AboutUs (){
    return(
        <>
       <AboutSectionone />
       <Aboutsectiontwo />
       <Aboutsectionthree />
       <Aboutsectionfour />
       {/* <h1>Test About</h1> */}
        </>
    )
}
export default AboutUs;