import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Disclaimer.css";
import "../InactiveClient/InactiveClient";

function Disclaimer(){
    return(
        <>
            <section className="static-bg static-pg inactive-sc">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className="static-heading">
                                <h2>Disclaimer</h2>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <p>The contents of this site, any service or product available on it and availed by you are provided to you without any warranty by BHAIJEE or this site. You acknowledge that any warranty that is provided in connection with any of the products or services described herein is provided solely by the owner, advertiser, manufacturer or supplier of that product and/or service, and not by BHAIJEE. Though all best possible efforts are made by BHAIJEE for your best navigation of this site but BHAIJEE does not warrant that your access to the site and/or related services will be uninterrupted or error-free, that defects will be corrected, or that this site or the server that makes it available is free of viruses or other harmful components. Access to and use of this site and the information is at your risk and BHAIJEE does not undertake any accountability for any irregularities, viruses or damage to any computer that results from accessing, availing or downloading of information from this site. BHAIJEE does not warrant or make any representations regarding the use or the results of the use of any product and/or service purchased in terms of its compatibility, correctness, accuracy, reliability or otherwise. You assume total responsibility and risk for your use of this site and site-related services.</p>

                            <p>You agree that, except as provided under the BHAIJEE return policy, BHAIJEE and its directors, officers, employees, agents, sponsors, consultants, business partners or other representatives ('service providers') shall not be responsible or liable for any direct, indirect, incidental, consequential, special, exemplary, punitive or any other damages (including without limitation loss of profits, loss or corruption of data, loss of goodwill, work stoppage, computer failure or malfunction, or interruption of business) under any contract, negligence, strict liability or other theory arising out of or relating in any way with the use of the site or in reliance of the information available on the site, site-related services, or any products or services offered or sold or displayed on BHAIJEEindia.com.</p>

                            <p>If the foregoing limitation is held to be unenforceable, the maximum liability of BHAIJEE and its service providers to you shall not exceed the amount of fees paid by you for the products or services that you have ordered through the site.</p>

                            <p>Although BHAIJEE makes best efforts to ensure the integrity, correctness and authenticity of the data available on the site, it makes no guarantees whatsoever as to its completeness, correctness or accuracy. In the event that such an inaccuracy arises, please inform BHAIJEE so that it can be corrected.</p>

                            <p>BHAIJEE does not make any recommeBHAIJEEtions to buy, sell or hold a particular security. The information on our Internet site is provided solely to enable investors to make their own investment decisions and they themselves are responsible for profits/losses arising out of their dealings on the stock market.</p>

                            <p>This Internet site is available only to Indian Residents and is presently not available to Foreign Nationals and Non Resident Indians in foreign jurisdiction where the services cannot be offered without prior regulatory compliance. In such cases due efforts are been made to comply with regulatory requirements in various jurisdictions which shall be notified from time to time. This service does not constitute an offer to sale or a solicitation to any person in any jurisdiction where it is unlawful to make such an offer or solicitation.</p>

                            <p>You understand and agree that no joint venture, partnership, employment or agency relationship exists between you and us as a result of this agreement or on account of use of our web site. </p>

                            <p>Price and availability of products and services offered on the site are subject to change without prior notice. BHAIJEE shall not be liable for any lack of availability of products and services you may order through the site.</p>

                            <h3>Governing Law</h3>
                            <p>Transactions between you and BHAIJEE are subject to the laws in force for the time being in India and any litigation regarding this agreement or any transaction between customer and BHAIJEE or any action at law or in equity arising out of or relating to these agreement or transaction shall subject to the jurisdiction of the Courts in Delhi/New Delhi alone and the customer hereby agrees, consents and submits to the jurisdiction of such courts for the purpose of litigating any such action.</p>
                            <h3>Disclaimer</h3>
                            <p>National Stock Exchange of India Limited, Bombay Stock Exchange Limited and Securities and Exchange Board of India are not in any manner answerable, responsible or liable to any person or persons for any acts of omission or commission, errors, mistakes and/or violation, actual or perceived, by us or our partners, agents, associates etc., of any of the Rules, Regulations, Bye-laws or any other laws in force from time to time. The Stock Exchanges or the SEBI are not answerable, responsible or liable for any information on this Website or for any services rendered by BHAIJEE, its employees or associates.</p>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Disclaimer;