import React from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import "./History.css";
import brief from "../../../Assests/images/brief.png";
function History() {
  return (
    <>
      <section className="history">
        <Container>
          <Row>
            <Col xs={12} md={5} lg={5}>
              <div className="history-alpha">
                <img className="img-fluid" src={brief} alt="some-image" />
              </div>
            </Col>
            <Col xs={12} md={7} lg={7}>
              <div className="history-beta">
                <h2>Brief History, Growth and Development</h2>
                <p>
                  Bhaijee was founded by Mr. Satish Kumar Gupta, Promoter and
                  Director of the Company. The management is run on professional
                  lines to deliver the best results. Bhaijee Group is committed
                  towards excellence in customer satisfaction. Bhaijee Group has
                  followed consistent growth and is established as stock broker
                  in 1995.
                </p>
                {/* <Button type="submit" className="read-more">
                  Read More
                </Button> */}
                <a href="/AboutUs"  className="read-more">Read More</a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default History;
