import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import './aboutsectionthree.css';
const data = [
    {
      img : './images/about1.png',
      years: "April 1995",
      tital : " Incorporation "
    },
  {
    img : './images/about2.png',
    years: "November 2004",
    tital : " NCDEX Membership "
  },
  {
    img : './images/about3.png',
    years: "January 2006",
    tital : " MCX Membership "
  },
  {
    img : './images/about4.png',
    years: "October 2015",
    tital : "CDSL DP Registration "
  },
  {
    img : './images/about5.png',
    years: "September 2018",
    tital : "Bombay Stock Exchange BSE Membership "
  },
  {
    img : './images/about6.png',
    years: "September 2018",
    tital : "National Stock Exchange BSE Membership "
  },
]



function aboutsectionthree() {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: false,
        responsive : [{
            breakpoint: 991,
            settings :   {
                slidesToShow: 4
            },

            breakpoint: 767,
            settings :   {
                slidesToShow: 3
            },
            breakpoint: 575,
            settings :   {
                slidesToShow: 2
            }
      

        }]
      }

           return(
        <>
      <Container>
        <Row>
            <Col className="aboutThreesec">
            <Slider {...settings}>  
                     
            {data && data.map((data, i) => {
                  return (
                    <div className="aboutthree">
                      <ul key={i}>
                      <li> <img src={data.img} /> </li>
                      <li> {data.years} </li>
                      <li> {data.tital} </li>
                      </ul>
                    </div>
                  );
                })}
      </Slider>
            </Col>
        </Row>
      </Container>
        </>
    )
}
export default aboutsectionthree;